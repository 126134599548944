import React, {useState} from "react";
import Select, { components } from "react-select";
import { setCity } from "../../reducers/Home/actions";
import { connect } from "react-redux";
import "./styles.scss";

const DropdownSearch = (props) => {
  const { description, text, image, selectClassName, cities, districts, onChange, onInputChange, value } = props;

  const [place, setPlaceValue] = useState(null)

  const DropdownIndicator = (props) => {
    return null;
  };

  let formatedCities = cities.map((c) => {
    return { value: c.zip_code, label: c.name, id: c.id };
  });
  let formatedDistricts = districts.map((c) => {
    if (c.name === 'София-град') {
      return { value: c.zip_code, label: 'София' , id: c.id, district_id: c.id }
    }
    return { value: c.zip_code, label: `${c.name}` , id: c.id, district_id: c.id }
  });
  formatedCities = formatedCities.sort((a,b) => a.label - b.label)
  let options = [...formatedCities, ...formatedDistricts]

  if (place) {
    formatedCities = formatedCities.slice(4)
    options = [...formatedCities]
  }

  if (!Number(place)) {
    options = [...formatedCities]
  }
  if (Number(place) && formatedDistricts.find(c => c.value.startsWith(place))) {
    let suggestedOption = formatedDistricts.find(c => c.value.startsWith(place))
    formatedCities = formatedCities.filter(c => c.label !== suggestedOption.label)
    options = [...formatedCities, suggestedOption]
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Няма намерени резултати...</span>
      </components.NoOptionsMessage>
    );
  };

  const groupedOptions = ({ label, value }, string) => {
    const capitalizeString = string.charAt(0).toUpperCase() + string.slice(1);

    if (label.startsWith(capitalizeString) || value.startsWith(string)) return true;
    return false;
  };

  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        position: "absolute",
        display: 'flex'
      };
    },
    singleValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        position: "absolute",
        color: "gray",
      };
    },
    valueContainer: (defaultStyles) => {
      return {
        ...defaultStyles,
        display: "flex",
        marginLeft: "12%",
      };
    },
    input: (defaultStyles) => {
      return {
        ...defaultStyles,
      };
    },
    container: (defaultStyles) => {
      return {
        ...defaultStyles,

        height: "72px",
        alignSelf: "center",
      };
    },
    control: (defaultStyles) => {
      return {
        ...defaultStyles,
        width: "100%",
        height: "100%",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        border: "none !important",
      };
    },
    indicatorSeparator: (defaultStyles) => {
      return {
        ...defaultStyles,
        display: "none",
      };
    },
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        textAlign: "left",
        zIndex: "1 !important",
        width: "100%",
        marginTop: '0.125rem'
      };
    },
  };

  // let obj = {district_id: 2, name: 'Грудово', zip_code: '1234'}

  // let objKey = Object.keys(obj)

  return (
    <div className="dropdown-container">
      <h6 className="dropdown-description">{description}</h6>
      <div className="dropdown-container__select-wrapper">
        <div className="select-icon">{image}</div>
        <Select
          value={value ? value : text}
          className={selectClassName ? selectClassName : "dropdown-select"}
          components={{
            NoOptionsMessage,
            DropdownIndicator
          }}
          placeholder={!value || value === '' ? text : value}
          options={options}
          styles={customStyles}
          openMenuOnFocus={false}
          openMenuOnClick={true}
          // backspaceRemovesValue={true}
          // isOptionSelected
          onInputChange={(e) => {
            setPlaceValue(e)
            onInputChange(e)
          }}
          isClearable={true}
          filterOption={groupedOptions}
          onChange={e => onChange(e)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cities: state.home.cities,
  districts: state.home.districts
});

const mapDispatchToProps = {
  setCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownSearch);
